<template>
  <BaseCard :disabled="disabled">
    <template #content>
      <v-form-base
        :model="model"
        :schema="schema"
        @input="handleInput"
      />
      <BaseAlert
        text="Нет атрибутов в группе"
        v-if="Object.keys(attributes.relatedAttributes).length === 0"
      />
    </template>
  </BaseCard>
</template>

<script>
import VFormBase from 'vuetify-form-base'
import BaseCard from '@/components/base/BaseCard'
import BaseAlert from '@/components/base/UI/BaseAlert'
import validationErrors from '@/components/mixins/validationErrors'
import { cleanClone } from '@/components/utils/common'

export default {
  name: 'DetailGroup',
  components: { BaseCard, VFormBase, BaseAlert },
  mixins: [validationErrors],
  props: {
    value: Object,
    attributes: Object,
    disabled: { Boolean, default: false },
    classForm: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      //note: завел переменную, чтобы сбрасывать значение составных списков, если использовать пропс, то при создании объекта сохраняются старые значения и некорректно работает валидация
      localAttributes: cleanClone(this.attributes),
      detail: this.value,
      schema: {},
      model: {}
    }
  },
  inject: ['$v'],
  computed: {},
  watch: {
    '$v.$dirty': {
      handler (val) {
        this.$nextTick(() => {
          if (val) {
            for (let item in this.schema) {
              if (this.schema.hasOwnProperty(item) && this.schema[item].hasOwnProperty('errorMessages') && item !== 'geometry') {
                this.$set(this.schema[item], 'errorMessages', this.checkErrors('detail.properties.' + item))
              }
            }
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    handleInput (data) {
      if (data.obj.schema.relatedAttributes && data.obj.schema.relatedAttributes.length > 0) {
        data.obj.schema.relatedAttributes.forEach(item => {
          this.detail.properties[item.name] = item.value
        })
        this.detail.properties[data.obj.schema.name] = data.value
      } else {
        this.detail.properties = Object.assign(this.detail.properties, data.data)
      }
      if (this.schema[data.key].hasOwnProperty('errorMessages')) {
        this.schema[data.key].errorMessages = this.checkErrors('detail.properties.' + data.key)
      }
    },
    buildSchema () {
      this.localAttributes.relatedAttributes.forEach(element => {
        this.model[element.name] = this.detail.properties[element.name]
        if (element.relatedAttributes) {
          this.addModelRelated(element.relatedAttributes)
        }
        let schemaItem = {
          type: element.eavType.name === 'option' ? 'searchSelect' : element.eavType.name,
          label: element.label,
          categoryId: element.optionCategoryId,
          relatedAttributes: element.relatedAttributes,
          dense: true,
          name: element.name,
          outlined: true,
          clearable: true,
          disabled: !!element.attributeRule.locked,
          col: { cols: 12 },
          classForm: this.classForm,
          prependInnerIcon: element.attributeRule.required ? 'mdi-asterisk' : ''
        }
        if (this.$v.detail.properties[element.name]) {
          schemaItem.errorMessages = []
        }
        this.$set(this.schema, element.name, schemaItem)
      })
      return true
    },
    addModelRelated (element) {
      element.forEach((item) => {
        this.model[item.name] = this.detail.properties[item.name]
      })
    }
  },
  mounted () {
    this.buildSchema()
  }
}
</script>
