<template>
  <BaseCard
    :is-small-height="isSmallHeight"
    :class-content="classContent"
  >
    <template #content>
      <v-form-base
        :model="model"
        :schema="schema"
        @input="handleInput($event)"
      />
    </template>
  </BaseCard>
</template>

<script>
import VFormBase from 'vuetify-form-base'
import validationErrors from '@/components/mixins/validationErrors'
import BaseCard from '@/components/base/BaseCard'
import { cleanClone, filterAttributes, flatenObject, iconObjectStatus, sortWithNull } from '@/components/utils/common'

export default {
  mixins: [validationErrors],
  name: 'DetailView',
  components: { BaseCard, VFormBase },
  props: {
    value: Object,
    attributes: Array,
    disabled: Boolean,
    isSmallHeight: Boolean,
    classContent: { type: String, default: '' },
    classForm: String
  },
  data () {
    return {
      schema: {},
      detail: this.value,
      localAttributes: null
    }
  },
  computed: {
    model () {
      return this.detail.properties
    },
    icon () {
      return iconObjectStatus(this.detail.properties.state, this.detail.properties.verification_status, this.detail.properties.origin)
    }
  },
  inject: ['$v'],
  watch: {
    attributes: {
      handler () {
        // фильтруем здесь группы или геометрию
        this.localAttributes = cleanClone(this.attributes.filter(item => item.eavType.id !== 14 && item.eavType.id !== 10))
        this.buildSchema()
        // добавляем связанные поля для адекватной работы через $nextTick!
        this.$nextTick(() => {
          flatenObject(this.localAttributes, 'relatedAttributes')
            .filter(relatedAttribute => relatedAttribute.value)
            .forEach(relatedAttribute => this.detail.properties[relatedAttribute.name] = relatedAttribute.value)
        })
        this.localAttributes.map((item) => {
          item?.relatedAttributes?.sort(sortWithNull('order'))
          return item
        })
      },
      deep: true,
      immediate: true
    },
    '$v.$dirty': {
      handler (val) {
        if (val) {
          for (let item in this.schema) {
            if (this.schema.hasOwnProperty(item) && this.schema[item].hasOwnProperty('errorMessages')) {
              this.$set(this.schema[item], 'errorMessages', this.checkErrors('detail.properties.' + item))
            }
          }
        }
      },
      immediate: true
    },
    disabled: {
      handler (val, oldVal) {
        if (val !== oldVal) {
          this.buildSchema()
        }
      }
    }
  },
  methods: {
    handleInput (data) {
      // устанавливаем значения из составного списка
      if (data.obj.schema.relatedAttributes && data.obj.schema.relatedAttributes.length) {
        data.obj.schema.relatedAttributes.forEach(item => {
          this.detail.properties[item.name] = item.value
        })
      }
      // устанавливаем основные значения
      this.$set(this.detail.properties, data.key, data.value)
      // получаем сообщения об ошибках валидации
      if (this.schema[data.key].hasOwnProperty('errorMessages')) {
        this.schema[data.key].errorMessages = this.checkErrors('detail.properties.' + data.key)
      }
    },
    buildSchema () {
      return filterAttributes(this.localAttributes, ['id', 'element_number', 'state'])
        .forEach(item => {
            let schemaItem = {
              type: item.eavType.name === 'option' ? 'searchSelect' : item.eavType.name,
              label: item.label,
              categoryId: item.optionCategoryId,
              relatedAttributes: item.relatedAttributes,
              dense: true,
              outlined: true,
              clearable: true,
              classForm: this.classForm,
              disabled: !!item.attributeRule.locked || this.disabled,
              col: { cols: 12 },
              prependInnerIcon: item.attributeRule.required ? 'mdi-asterisk' : ''
            }
            if (this.$v.detail.properties[item.name]) {
              schemaItem.errorMessages = []
            }
            this.$set(this.schema, item.name, schemaItem)
          }
        )
    }
  }
}
</script>

<style scoped>

</style>
