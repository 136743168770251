import { maxLength, maxValue, minValue, required } from 'vuelidate/lib/validators'
import { flatenObject } from '@/components/utils/common'

export default {
  validations () {
    let validations = {
      detail: {
        properties: {},
        //geometry: { type: { required }, coordinates: { required, validCoordinates } }
      }
    }

    const flatObject = flatenObject(this.objectAttributes,'relatedAttributes')
    flatObject.forEach(attribute => {
      let validation = {}
        if (attribute.attributeRule.required && attribute.eavType !== 2) {
          validation.required = required
        }
        if (attribute.attributeRule.rules?.length > 1) {
          if (attribute.attributeRule.rules[0] === 'string') {
            if (attribute.attributeRule.rules[1].hasOwnProperty('max')) {
              validation.maxLength = maxLength(attribute.attributeRule.rules[1].max)
            }
          } else if (attribute.attributeRule.rules[0] === 'integer') {
            if (attribute.attributeRule.rules[1].hasOwnProperty('min')) {
              validation.minValue = minValue(attribute.attributeRule.rules[1].min)
            }
            if (attribute.attributeRule.rules[1].hasOwnProperty('max')) {
              validation.maxValue = maxValue(attribute.attributeRule.rules[1].max)
            }
          }
        }
        if (Object.keys(validation).length) {
          if (attribute.name !== 'geometry') {
            validations.detail.properties[attribute.name] = validation
          }
        }
    })
    return validations
  }
}
